import React, { useState, useEffect } from "react"
import GiftCardCertificate from "../components/GiftCardCertificate"
import GiftCardCertificateNewCards from "../components/GiftCardCertificateNewCards"
import LoadingComponent from "../components/LoadingComponent"

const checkURLHash = async (
  certificate_id,
  setGiftDetails,
  setPageNotFound
) => {
  try {
    fetch(
      `${process.env.GATSBY_BACKEND}/api/v1/gift-certificate/${certificate_id}/`,
      {
        method: "GET",
        mode: "cors",
      }
    )
      .then(response => {
        if (response.status === 200) {
          return response.json()
        }
        throw Error("Invalid URL")
      })
      .then(result => {
        const giftDetails = result
        setGiftDetails(giftDetails)
      })
      .catch(error => {
        setPageNotFound(true)
      })
  } catch (error) {
    setPageNotFound(true)
  }
}

export default props => {
  const [giftDetails, setGiftDetails] = useState(null)
  const [pageNotFound, setPageNotFound] = useState(false)

  useEffect(() => {
    if (props.location.search) {
      const urlHash = new URLSearchParams(props.location.search)
      const certificate_id = urlHash.get("id")
      checkURLHash(certificate_id, setGiftDetails, setPageNotFound)
    }
    // else {
    //   window.location.replace(`${process.env.GATSBY_EGIFTIT_FRONTEND}/404/`)
    // }
  }, [])

  return (
    <article className="gift-certificate-page">
      {!giftDetails ? (
        <>
          {!pageNotFound ? (
            <LoadingComponent />
          ) : (
            <p>{JSON.stringify({ test: 1 })}</p>
          )}{" "}
        </>
      ) : (
        <>
          {giftDetails.extra_info ? (
            <GiftCardCertificateNewCards
              giftDetails={giftDetails}
              data={props.data}
            />
          ) : (
            <GiftCardCertificate giftDetails={giftDetails} data={props.data} />
          )}
        </>
      )}
    </article>
  )
}
